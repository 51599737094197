import { axiosFetchBase } from "@/services/http.js";
import { createApi } from "@reduxjs/toolkit/query/react";

export const legalDocumentApi = createApi({
	reducerPath: "legalDocumentApi",
	tagTypes: ["legal-documents"],
	baseQuery: axiosFetchBase("/legal-documents"),
	endpoints: (builder) => ({
		getLegalDocuments: builder.query({
			query: () => ({
				url: "/",
				method: "GET",
			}),
			providesTags: [{ type: "legal-documents", id: "list" }],
		}),
		createLegalDocument: builder.mutation({
			query: (arg) => ({
				url: "/",
				method: "POST",
				files: arg.files,
				onUploadProgress: arg.onUploadProgress,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
			invalidatesTags: [{ type: "legal-documents", id: "list" }],
		}),
		deleteLegalDocument: builder.mutation({
			query: (data) => ({
				url: `/${data.id}`,
				method: "DELETE",
			}),
			invalidatesTags: (_, __, arg) => [
				{ type: "legal-documents", id: "list" },
				{ type: "legal-documents", id: arg.id },
			],
		}),
	}),
});

export const {
	useGetLegalDocumentsQuery,
	useCreateLegalDocumentMutation,
	useDeleteLegalDocumentMutation,
} = legalDocumentApi;
