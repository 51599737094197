import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "./http";

export const inhouseApi = createApi({
    reducerPath: "inhouseApi",
    tagTypes: ["inhouse"],
    baseQuery: axiosFetchBase("inhouse"),
    endpoints: (builder) => ({
        getInhouseRequests: builder.query({
            query: (data) => ({
                url: "/",
                method: "GET",
                query: {
                    search: data.search,
                    page: data.page,
                    pageSize: data.pageSize,
                    unread: data.unread,
                    status: data.status,
                },
            }),
            providesTags: [{ type: "inhouse", id: "list" }],
        }),
        getInhouseRequestById: builder.query({
            query: (id) => ({
                url: `/${id}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [{ type: "inhouse", id: arg.id }],
        }),
        saveInhouseRequest: builder.mutation({
            query: (data) => ({
                url: "/save",
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "inhouse", id: "list" },
                { type: "inhouse", id: arg.id },
            ],
        }),
        deleteInhouseRequest: builder.mutation({
            query: (data) => ({
                url: "/delete",
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "inhouse", id: "list" },
                { type: "inhouse", id: arg.id },
            ],
        }),
        updateReadStatus: builder.mutation({
            query: (data) => ({
                url: "/read/update",
                method: "POST",
                body: {
                    id: data.id,
                    is_unread: data.unread
                }
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "inhouse", id: "list" },
                { type: "inhouse", id: arg.id },
            ],
        }),
        sendInhouseRequest: builder.mutation({
            query: (data) => ({
                url: "/send",
                method: "POST",
                body: data,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "inhouse", id: "list" },
                { type: "inhouse", id: arg.id },
            ],
        }),
    }),
});

export const {
    useGetInhouseRequestsQuery,
    useGetInhouseRequestByIdQuery,
    useSaveInhouseRequestMutation,
    useDeleteInhouseRequestMutation,
    useUpdateReadStatusMutation,
    useSendInhouseRequestMutation,
} = inhouseApi;
