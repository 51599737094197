import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeYearEndClosingApi = createApi({
    reducerPath: "financeYearEndClosingApi",
    tagTypes: ["finance-year-end-closings"],
    baseQuery: axiosFetchBase("/finance-year-end-closings"),
    endpoints: (builder) => ({
        getYearEndClosings: builder.query({
            query: () => ({
                url: "/",
                method: "GET",
            }),
            providesTags: [{ type: "finance-year-end-closings", id: "list" }],
        }),
        getYearEndClosingById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-year-end-closings", id: arg.id },
            ],
        }),
        createYearEndClosing: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: {
                    year: data.year,
                    value: data.value.replaceAll(",", "."),
                },
            }),
            invalidatesTags: [
                { type: "finance-year-end-closings", id: "list" },
            ],
        }),
        updateYearEndClosing: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: {
                    year: data.year,
                    value: data.value.replaceAll(",", "."),
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-year-end-closings", id: "list" },
                { type: "finance-year-end-closings", id: arg.journalId },
            ],
        }),
    }),
});

export const {
    useGetYearEndClosingsQuery,
    useGetYearEndClosingByIdQuery,
    useCreateYearEndClosingMutation,
    useUpdateYearEndClosingMutation,
} = financeYearEndClosingApi;
