import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "./http";

export const approvalApi = createApi({
    reducerPath: "approvalApi",
    tagTypes: ["approval"],
    baseQuery: axiosFetchBase("/transaction-monitor"),
    endpoints: (builder) => ({
        approveTransaction: builder.mutation({
            query: (data) => ({
                url: "/approve",
                method: "POST",
                body: data,
            }),
        }),
        creditTransaction: builder.mutation({
            query: (data) => ({
                url: "/credit",
                method: "POST",
                body: data,
            }),
        }),
        creditApproveTransaction: builder.mutation({
            query: (data) => ({
                url: "/credit-approve",
                method: "POST",
                body: data,
            }),
        }),
        deferredTransaction: builder.mutation({
            query: (data) => ({
                url: "/deferred",
                method: "POST",
                body: data,
            }),
        }),
        deferredApproveTransaction: builder.mutation({
            query: (data) => ({
                url: "/deferred-approve",
                method: "POST",
                body: data,
            }),
        }),
        rejurnalTransaction: builder.mutation({
            query: (data) => ({
                url: "/rejurnal",
                method: "POST",
                body: data,
            }),
        }),
        refundTransaction: builder.mutation({
            query: (data) => ({
                url: "/refund",
                method: "POST",
                body: data,
            }),
        }),
        uploadTaxInvoice: builder.mutation({
            query: (data) => ({
                url: "/upload-faktur",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useApproveTransactionMutation,
    useCreditTransactionMutation,
    useCreditApproveTransactionMutation,
    useDeferredTransactionMutation,
    useDeferredApproveTransactionMutation,
    useRejurnalTransactionMutation,
    useRefundTransactionMutation,
    useUploadTaxInvoiceMutation,
} = approvalApi;
