import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeJournalApi = createApi({
    reducerPath: "financeJournalApi",
    tagTypes: ["finance-journals"],
    baseQuery: axiosFetchBase("/finance-journals"),
    endpoints: (builder) => ({
        getRkapClosingDate: builder.query({
            query: () => ({
                url: "/rkap-closing-date",
                method: "GET",
            }),
            providesTags: [
                { type: "finance-journals", id: "rkap-closing-date" },
            ],
        }),
        getRkapStatus: builder.query({
            query: () => ({
                url: "/rkap-status",
                method: "GET",
            }),
            providesTags: [{ type: "finance-journals", id: "rkap-status" }],
        }),
        getFinanceJournals: builder.query({
            query: (data) => ({
                url: "/",
                method: "GET",
                query: {
                    filter: data.filter,
                    search: data.search,
                    page: data.page,
                    pageSize: data.pageSize,
                    startDate: data.startDate,
                    endDate: data.endDate,
                },
            }),
            providesTags: [
                {
                    type: "finance-journals",
                    id: "list",
                },
            ],
        }),
        getFinanceJournalById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-journals", id: arg.id },
            ],
        }),
        searchFinanceJournalVoucher: builder.query({
            query: (data) => ({
                url: "/voucher",
                method: "GET",
                query: {
                    field: data.field,
                    search: data.search,
                },
            }),
            providesTags: [{ type: "finance-journals", id: "vouchers" }],
        }),
        postFinanceJournal: builder.mutation({
            query: (data) => ({
                url: `/post/${data.journalId}`,
                method: "POST",
            }),
            invalidatesTags: ["finance-journals"],
        }),
        addFinanceJournal: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: {
                    no_voucher: data.no_voucher,
                    tgl_accountingjournal: data.tgl_accountingjournal,
                    no_form: data.no_form,
                    ref_journal: data.ref_journal,
                    comt_journal: data.comt_journal,
                },
            }),
            invalidatesTags: ["finance-journals"],
        }),
        addFinanceJournalDetail: builder.mutation({
            query: (data) => ({
                url: "/detail",
                method: "POST",
                body: {
                    id_accountingjournal: data.journalId,
                    acct_position: data.accountPosition,
                    journal_value: data.journalValue,
                    journal_qty: data.journalQty,
                    id_account: data.accountId,
                    comt_journaldetail: data.comtJournalDetail,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-journals", id: "list" },
                { type: "finance-journals", id: arg.journalId },
            ],
        }),
        updateFinanceJournal: builder.mutation({
            query: (data) => ({
                url: `/${data.journalId}`,
                method: "PUT",
                body: {
                    no_voucher: data.no_voucher,
                    tgl_accountingjournal: data.tgl_accountingjournal,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-journals", id: "list" },
                { type: "finance-journals", id: arg.journalId },
            ],
        }),
        updateFinanceJournalDetail: builder.mutation({
            query: (data) => ({
                url: `/detail/${data.id}`,
                method: "PUT",
                body: {
                    acct_position: data.accountPosition,
                    journal_value: data.journalValue,
                    id_account: data.accountId,
                    comt_journaldetail: data.comtJournalDetail,
                },
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-journals", id: "list" },
                { type: "finance-journals", id: arg.journalId },
            ],
        }),
        deleteFinanceJournal: builder.mutation({
            query: (data) => ({
                url: `/${data.journalId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-journals", id: "list" },
                { type: "finance-journals", id: arg.journalId },
            ],
        }),
        deleteFinanceJournalDetail: builder.mutation({
            query: (data) => ({
                url: `/detail/${data.journalId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-journals", id: "list" },
                { type: "finance-journals", id: arg.journalId },
            ],
        }),
    }),
});

export const {
    useGetRkapClosingDateQuery,
    useGetRkapStatusQuery,
    useGetFinanceJournalsQuery,
    useGetFinanceJournalByIdQuery,
    useSearchFinanceJournalVoucherQuery,
    useAddFinanceJournalMutation,
    useAddFinanceJournalDetailMutation,
    usePostFinanceJournalMutation,
    useUpdateFinanceJournalMutation,
    useUpdateFinanceJournalDetailMutation,
    useDeleteFinanceJournalMutation,
    useDeleteFinanceJournalDetailMutation,
} = financeJournalApi;
