import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "./http";

export const blogApi = createApi({
    reducerPath: "blogApi",
    tagTypes: ["blogs"],
    baseQuery: axiosFetchBase("/blog"),
    endpoints: (builder) => ({
        getArticles: builder.query({
            query: (data) => ({
                url: "/",
                method: "GET",
                query: {
                    page: data.page,
                    search: data.search,
                    pageSize: data.pageSize,
                },
            }),
            providesTags: ["blogs"],
        }),
        getBlogTags: builder.query({
            query: (data) => ({
                url: "/tags",
                method: "GET",
                query: {
                    search: data.search,
                },
            }),
            providesTags: [{ type: "blogs", id: "tags" }],
        }),
        deleteArticle: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["blogs"],
        }),
        createArticle: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["blogs"],
        }),
        updateArticle: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["blogs"],
        }),
        uploadImage: builder.mutation({
            query: (data) => ({
                url: "/image/upload",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["blogs"],
        }),
    }),
});

export const {
    useGetArticlesQuery,
    useGetBlogTagsQuery,
    useDeleteArticleMutation,
    useCreateArticleMutation,
    useUpdateArticleMutation,
    useUploadImageMutation,
} = blogApi;
