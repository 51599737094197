const AUTH_TOKEN = "AUTH_TOKEN";

type Option = {
    value: string | number;
    label: string;
};

const PRODUCT_TYPE: Option[] = [
    { value: "Produk Baru", label: "Produk Baru" },
    { value: "Produk Lama", label: "Produk Lama" },
];

const INHOUSE_STATUS_FILTERS: Option[] = [
    { value: 1, label: "Penawaran" },
    { value: 2, label: "Deal" },
    { value: 3, label: "Belum direspon" },
    { value: 4, label: "Cancel" },
    { value: "", label: "Semua" },
];

const MARKET_PRODUCT_TYPE: Option[] = [
    { value: "Pasar Baru", label: "Pasar Baru" },
    { value: "Pasar Lama", label: "Pasar Lama" },
];

const STRATEGI_GENERIK_ANSOFF: Option[] = [
    { value: "Diversifikasi", label: "Diversifikasi" },
    { value: "Pengembangan Pasar", label: "Pengembangan Pasar" },
    { value: "Pengembangan Produk", label: "Pengembangan Produk" },
    { value: "Intensifikasi", label: "Intensifikasi" },
];

const GENDER: Option[] = [
    { value: "Bapak", label: "Bapak" },
    { value: "Ibu", label: "Ibu" },
];

const QUESTION_TYPE: Option[] = [
    { value: "pilihan-ganda", label: "Pilihan Ganda" },
    { value: "isian-singkat", label: "Isian Singkat" },
    { value: "menjodohkan", label: "Menjodohkan" },
    { value: "benar-salah", label: "Benar-salah" },
];

const TIME_CHOICE: Option[] = [
    { value: 15, label: "15 detik" },
    { value: 30, label: "30 detik" },
    { value: 45, label: "45 detik" },
    { value: 60, label: "1 menit 0 detik" },
    { value: 75, label: "1 menit 15 detik" },
    { value: 90, label: "1 menit 30 detik" },
    { value: 105, label: "1 menit 45 detik" },
    { value: 120, label: "2 menit 0 detik" },
    { value: 135, label: "2 menit 15 detik" },
    { value: 150, label: "2 menit 30 detik" },
];

const BENAR_SALAH: Option[] = [
    { value: 1, label: "Benar" },
    { value: 0, label: "Salah" },
];

const QUESTION_POINTS: Option[] = Array.from({ length: 20 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1} poin`,
}));

const COLOR_BLUE_IPBT = "#2bb2e7";
const COLOR_GREEN_IPBT = "#a6ce39";
const COLOR_RED_IPBT = "#ee1d23";
const COLOR_ORANGE_IPBT = "#f58220";
const COLOR_YELLOW_IPBT = "#feca0a";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const SHOWPAGINATION: Option[] = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
];

const CATEGORYKPI: Option[] = [
    { value: "routine", label: "Routine" },
    { value: "improvement", label: "Improvement" },
    { value: "tambahan", label: "Tambahan" },
];

const PERSPEKTIF: Option[] = [
    { value: "finansial", label: "Finansial" },
    { value: "pelanggan", label: "Pelanggan" },
    { value: "proses bisnis", label: "Proses bisnis" },
    {
        value: "pembelajaran dan pertumbuhan",
        label: "Pembelajaran dan pertumbuhan",
    },
    { value: "it", label: "Information Technology" },
];

const DIRECTUR = "Muhammad Sigit Susanto";

// TRANSACTION_STATUS
const TRANSACTION_PENDING = 2;
const TRANSACTION_PIUTANG = 3;
const TRANSACTION_SUCCESS = 4;
const TRANSACTION_PDDM = 5;
const TRANSACTION_REFUND = 6;

// INAHEX EXHIBITOR
const KATEGORI_PLATINUM = 1;
const KATEGORI_GOLD = 2;
const KATEGORI_SILVER = 3;
const KATEGORI_BRONZE = 4;

// TYPE IPP
const TARGET_IPP_NUMBER = 1;
const TARGET_IPP_DATE = 2;
const TARGET_IPP_PERCENTAGE = 3;
const TARGET_IPP_AVERAGE = 4;

// STATUS BROADCAST
const BROADCAST_DRAFT = 0;
const BROADCAST_SENDING = 1;
const BROADCAST_SENDED = 2;
const BROADCAST_SCHEDULE = 3;
const BROADCAST_PARTIAL = 4;

const PROFESI_LIST = [
    "Dosen",
    "Peneliti",
    "Dokter Hewan",
    "Paramedik veteriner",
    "Mahasiswa pascasarjana",
    "QA",
    "QC",
    "Operasional",
    "Marketing",
    "Keuangan",
    "Akuntansi",
    "HR (SDM)",
    "Legal",
    "Administrasi",
    "General Affairs",
    "Salesperson",
    "Laboran",
    "Data analist",
    "Sistem informasi",
    "Teknologi informasi",
    "Lainnya",
];
const PROFESI: Option[] = PROFESI_LIST.map((profesi) => ({
    label: profesi,
    value: profesi,
}));

const MONTHS: Option[] = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
].map((month, i) => ({ value: i + 1, label: month }));

export const CONSTANTS = {
    AUTH_TOKEN,
    BENAR_SALAH,
    BROADCAST_DRAFT,
    BROADCAST_SCHEDULE,
    BROADCAST_SENDED,
    BROADCAST_SENDING,
    BROADCAST_PARTIAL,
    CATEGORYKPI,
    COLOR_BLUE_IPBT,
    COLOR_GREEN_IPBT,
    COLOR_ORANGE_IPBT,
    COLOR_RED_IPBT,
    COLOR_YELLOW_IPBT,
    DIRECTUR,
    GENDER,
    INHOUSE_STATUS_FILTERS,
    KATEGORI_BRONZE,
    KATEGORI_GOLD,
    KATEGORI_PLATINUM,
    KATEGORI_SILVER,
    MARKET_PRODUCT_TYPE,
    MONTHS,
    PERSPEKTIF,
    PRODUCT_TYPE,
    PROFESI,
    QUESTION_POINTS,
    QUESTION_TYPE,
    SHOWPAGINATION,
    STRATEGI_GENERIK_ANSOFF,
    SUPPORTED_FORMATS,
    TARGET_IPP_AVERAGE,
    TARGET_IPP_DATE,
    TARGET_IPP_NUMBER,
    TARGET_IPP_PERCENTAGE,
    TIME_CHOICE,
    TRANSACTION_PDDM,
    TRANSACTION_PENDING,
    TRANSACTION_PIUTANG,
    TRANSACTION_REFUND,
    TRANSACTION_SUCCESS,
};
