import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeAccountApi = createApi({
    reducerPath: "financeAccountApi",
    tagTypes: ["finance-accounts"],
    baseQuery: axiosFetchBase("/finance-accounts"),
    endpoints: (builder) => ({
        getFinanceAccounts: builder.query({
            query: (args) => ({
                url: "/",
                method: "GET",
                query: {
                    search: args.search,
                    page: args.page,
                    pageSize: args.pageSize,
                },
            }),
            providesTags: [{ type: "finance-accounts", id: "list" }],
        }),
        getFinanceAccountCodes: builder.query({
            query: (args) => ({
                url: "/codes",
                method: "GET",
                query: {
                    type: args.type, // 3, 4, 5
                    search: args.search,
                    parent_id: args.parentId,
                },
            }),
            providesTags: [{ type: "finance-accounts", id: "account-codes" }],
        }),
        getWorksheetSummary: builder.query({
            query: (args) => ({
                url: "/worksheet",
                method: "GET",
                query: {
                    year: args.year,
                    month: args.month,
                },
            }),
            providesTags: [{ type: "finance-accounts", id: "worksheet" }],
        }),
        getReportSummary: builder.query({
            query: (args) => ({
                url: "/report",
                method: "GET",
                query: {
                    year: args.year,
                    month: args.month,
                },
            }),
            providesTags: [{ type: "finance-accounts", id: "report" }],
        }),
        getProfitLossSummary: builder.query({
            query: (args) => ({
                url: "/profit-loss",
                method: "GET",
                query: {
                    year: args.year,
                    month: args.month,
                },
            }),
            providesTags: [{ type: "finance-accounts", id: "profit-loss" }],
        }),
        searchFinanceAccounts: builder.query({
            query: (args) => ({
                url: "/search",
                method: "GET",
                query: {
                    field: args.field,
                    search: args.search,
                    limitPaymentAccount: args.limitPaymentAccount,
                },
            }),
            providesTags: (_, __, args) => [
                {
                    type: "finance-accounts",
                    id: "list",
                    field: args.field,
                    search: args.search,
                },
            ],
        }),
        getFinanceAccountById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-accounts", id: arg.id },
            ],
        }),
        createFinanceAccount: builder.mutation({
            query: (data) => ({
                url: "/",
                method: "POST",
                body: data,
            }),
            invalidatesTags: [{ type: "finance-accounts", id: "list" }],
        }),
        updateFinanceAccount: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-accounts", id: "list" },
                { type: "finance-accounts", id: arg.id },
            ],
        }),
        deleteFinanceAccount: builder.mutation({
            query: (data) => ({
                url: `/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "finance-accounts", id: "list" },
                { type: "finance-accounts", id: arg.id },
            ],
        }),
        searchFinanceAccountGroups: builder.query({
            query: (args) => ({
                url: "/groups",
                method: "GET",
                query: {
                    search: args.search,
                },
            }),
            providesTags: (_, __, args) => [
                {
                    type: "finance-accounts",
                    id: "groups",
                    search: args.search,
                },
            ],
        }),
        searchFinanceAccountSubGroups: builder.query({
            query: (data) => ({
                url: "/sub-groups",
                method: "GET",
                query: {
                    search: data.search,
                    group: data.group,
                },
            }),
            providesTags: (_, __, arg) => [
                {
                    type: "finance-accounts",
                    id: "sub-groups",
                    group: arg.group,
                },
            ],
        }),
        searchFinanceAccountTypes: builder.query({
            query: (data) => ({
                url: "/types",
                method: "GET",
                query: {
                    search: data.search,
                    group: data.group,
                    subGroup: data.subGroup,
                },
            }),
            providesTags: (_, __, arg) => [
                {
                    type: "finance-accounts",
                    id: "types",
                    group: arg.group,
                    subGroup: arg.subGroup,
                },
            ],
        }),
        searchFinanceAccountsByFilter: builder.query({
            query: (data) => ({
                url: "/filtered-accounts",
                method: "GET",
                query: {
                    search: data.search,
                    group: data.group,
                    subGroup: data.subGroup,
                    type: data.type,
                },
            }),
            providesTags: (_, __, arg) => [
                {
                    type: "finance-accounts",
                    id: "filtered-accounts",
                    group: arg.group,
                    subGroup: arg.subGroup,
                },
            ],
        }),
    }),
});

export const {
    useGetFinanceAccountsQuery,
    useGetFinanceAccountCodesQuery,
    useLazyGetWorksheetSummaryQuery,
    useLazyGetReportSummaryQuery,
    useLazyGetProfitLossSummaryQuery,
    useSearchFinanceAccountsQuery,
    useSearchFinanceAccountGroupsQuery,
    useSearchFinanceAccountSubGroupsQuery,
    useSearchFinanceAccountTypesQuery,
    useSearchFinanceAccountsByFilterQuery,
    useGetFinanceAccountByIdQuery,
    useCreateFinanceAccountMutation,
    useUpdateFinanceAccountMutation,
    useDeleteFinanceAccountMutation,
} = financeAccountApi;
