import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeBalanceApi = createApi({
    reducerPath: "financeBalanceApi",
    tagTypes: ["finance-balances"],
    baseQuery: axiosFetchBase("/finance-balances"),
    endpoints: (builder) => ({
        getFinanceBalances: builder.query({
            query: (args) => ({
                url: "/",
                method: "GET",
                query: {
                    start: args.start,
                    end: args.end,
                    group: args.group,
                    subGroup: args.subGroup,
                    type: args.type,
                    account: args.account,
                },
            }),
            providesTags: [{ type: "finance-balances", id: "list" }],
        }),
    }),
});

export const { useLazyGetFinanceBalancesQuery } = financeBalanceApi;
